module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Z6G7R9HERM"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr","es","zh-TW","zh-CN","ja","de","ru","it","ar","th","vi","pt","pt-BR","tr"],"defaultLanguage":"en","siteUrl":"https://www.appconjugate.com","i18nextOptions":{"fallbackLng":"en","interpolation":{"escapeValue":false}},"localeJsonSourceName":"locales","pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/comingsoon","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/contact","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/about","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/privacy","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/terms","getLanguageFromPath":true,"excludeLanguages":[]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
